import React from 'react';
import './App.scss';
import Product from './Product';
import {Link} from 'react-router-dom';

const brands = [
    { name: 'Dripmore', logo: 'candy king logo__53583.jpg' },
    { name: 'Naked 100 EJuice', logo: 'Naked-100.jpg' },
    { name: 'themilkman', logo: 'themilkman.jpg' },
    { name: '46658_Aqua_Liquids_-_Logo_large', logo: '46658_Aqua_Liquids_-_Logo_large.png' },
    { name: 'jam-monster-logo', logo: 'jam-monster-logo.jpg' },
    { name: 'Jam_Monster_Salt', logo: 'Jam_Monster_Salt.png' },
    { name: 'keepit100.', logo: 'keepit100.jpeg' },
    { name: 'beard', logo: 'beard.jpg' },
    { name: 'airfactory', logo: 'airfactory.jpg' },
]

export default class Brands extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false,
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className='
                columns is-mobile is-multiline has-text-centered content' style={{paddingBottom: '20vh !important'}}>
                    {brands.map(brand => (
                        <div className='column is-half'>
                            <Link to={'/products/?manufacturer=' + encodeURI(brand.name)}>

                            <img src={`imgs/${brand.logo}`} className='image '></img>
                            </Link>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )

    }

}


