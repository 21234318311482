import React from 'react';
import './App.scss';
import {
    Link, Route, withRouter
} from "react-router-dom";
import Login from './Login';
import firebase, { db } from './firebase';
import cart from './cart';
import user from './user';

class Nav extends React.Component {

    constructor() {
        super();
        this.state = {
            user: null,
            cart_items: cart.getItems(),
            address: ''
        }
        this.menu_ref = React.createRef();
        this.burger_ref = React.createRef();
        firebase.auth().onAuthStateChanged((user) => {
            this.setState({ 'user': user })
            if (user && user.uid) db.collection('users').doc(user.uid)
                .onSnapshot(doc => this.setState({ 'user': { ...this.state.user, ...doc.data() } }));
        })
        user.get_address().then(address => this.setState({address: address}));
        user.on_address_change(address => this.setState({address: address}));
        cart.onChange((items) => this.setState({ cart_items: items }))
    }

    //  function getAddressAutocomplete() {
    //     // return `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURI(this.state.address)}&key=AIzaSyByT18jQSn0rcWWJ0jkR-8DqmKwgWIlP20`
    // }


    render() {

        const items = this.state.cart_items || [];
        const pathname = this.props.location.pathname
        const { user, address } = this.state;
        return (
            <React.Fragment>
                <nav className="navbar is-fixed-top" role="navigation">
                    <div className="navbar-brand">
                        {this.props.history.length > 2 ?
                            <span className='back-button navbar-item' onClick={() => this.props.history.goBack()}>
                                <span className="icon ">
                                    <i className="fas fa-chevron-left"></i>
                                </span>
                                Back
                            </span>
                            : <div className='navbar-item' />
                        }


                        {/* <Link className="navbar-item" to='/'>
                            <p className="is-family-primary is-size-3 has-text-white-ter logo">JuiceDoor</p>
                        </Link>
                        <div className="navbar-item" style={{flexDirection: 'column', alignItems: 'center'}}> */}
                        <div className="navbar-item" >
                            <Link to='/'>
                                <p className="is-family-primary is-size-3 has-text-white-ter logo">JuiceDoor</p>
                            </Link>
                        </div>
                        <div className="navbar-item" >
                            <Link to='/address-search'>
                                {address ?
                                    <React.Fragment>
                                        <p className="has-text-grey is-size-7" >{address.address_line}</p>
                                        <p className="has-text-grey is-size-7" >{address.city}, {address.state}</p>
                                    </React.Fragment>

                                    :
                                    <p>Enter your address</p>
                                }
                            </Link>

                            {/* <input className='input' type="text" value={this.state.address}/> */}

                        </div>
                    </div>
                </nav>

                {
                    !pathname.split('/').includes('product') ?
                        <nav className="navbar is-fixed-bottom" role="navigation" aria-label="main navigation">
                            {
                                items.length && !pathname.includes('/checkout') ?

                                    <Link to='/checkout' id="navbar-cart" className="navbar-brand has-text-center is-family-primary">
                                        <p className="navbar-item">{items.length}</p>
                                        <p className="navbar-item">View Cart</p>
                                        <p className="navbar-item">${cart.getSubtotal(true)}</p>
                                    </Link>
                                    : null
                            }
                            <Route path='/checkout' render={() =>
                                <Link to='/' id="navbar-checkout" className="navbar-brand has-text-center is-family-primary">
                                    <p className="navbar-item">Pay Now</p>
                                </Link>
                            } />
                            {
                                !pathname.includes('/checkout') && !pathname.split('/').includes('product') ?
                                    <div id="tab-nav" className="navbar-brand">
                                        <div className="navbar-item is-tab"><Link to='/popular' className="navbar-item"><p>🔥</p> <p>Popular</p></Link></div>
                                        <div className="navbar-item is-tab"><Link to='/flavors' className="navbar-item"><p>🌈</p> <p>Flavors</p></Link></div>
                                        <div className="navbar-item is-tab"><Link to='/brands' className="navbar-item"><p>🍷</p> <p>Brands</p></Link></div>
                                        <div className="navbar-item is-tab"><Link to='/orders' className="navbar-item"><p>🛸</p> <p>Orders</p></Link></div>
                                        <div className="navbar-item is-tab"><Link to={this.state.user && !this.state.user.isAnonymous ? '/account' : '/auth'} className="navbar-item"><p>👤</p> <p>Account</p></Link></div>
                                    </div>
                                    : null

                            }
                        </nav>
                        : null
                }
            </React.Fragment>
        )
    }
}

export default withRouter(Nav);