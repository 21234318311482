import React from 'react';
import firebase, { db } from 'firebase';
import storage from './storage';
import { async } from 'q';

const get = () => firebase.auth().currentUser;

const get_doc_data = async () => (await db.collection('users').doc(get().uid).get()).data();

const set_doc_data = async data => await db.collection('users').doc(get().uid).set(data, { merge: true });

const exists = () => get() !== null;


const _address_observers = [];
const update_address_observers = async address => _address_observers.forEach(async observer => await observer(address));
const on_address_change = async observer => _address_observers.push(observer);

const set_address = async address => {
    if (exists()) await set_doc_data({address: address});
    await storage.set('address', address);
    update_address_observers(address);
}


const get_address = async () => await _get_data_from_user_or_local_storage('address')

// Should only be used to initialize cart.js
const get_cart = async () => await _get_data_from_user_or_local_storage('cart')

const _get_data_from_user_or_local_storage = async key => {
    const local_storage_val = await storage.get(key);
    if (exists()) {
        if (local_storage_val) await set_doc_data({ key: local_storage_val });
        else {
            const data = await get_doc_data();
            if (data && data[key]) {
                await storage.set(key, data[key]);
                return data[key];
            }
        }
    }
    return local_storage_val
}

export default {
    get,
    get_doc_data,
    on_address_change,
    update_address_observers,
    set_address,
    set_doc_data,
    exists,
    get_address,
    get_cart,
}