import React from 'react';
import './App.scss';
import storage from './storage';
import Progress from './Progress';
import { CardElement, injectStripe } from 'react-stripe-elements';

 class Account extends React.Component {
    constructor() {
        super();
        this.state = {
            user: null,
            loading: true
        }
        storage.get('user')
            .then(user => this.setState({ user: user, loading: false }))
            .catch(err => console.error(err));
    }

    render() {
        const { user, loading } = this.state;
        console.log(user)
        return (
            <React.Fragment>
                {
                    loading &&
                    <Progress />

                }
                {
                    user && !loading &&
                    < div className='content'>
                        {
                            user.photoURL &&
                            <img src={user.photoURL} style={{ maxWidth: '100%' }} />
                        }
                        <div >
                            <span>Name</span>
                            <span className='is-pulled-right'>{user.displayName}</span>
                        </div>
                        <div >
                            <span>Email</span>
                            <span className='is-pulled-right'>{user.email}</span>
                        </div>
                        <div >
                            <span>Phone Number</span>
                            <span className='is-pulled-right'>{user.phoneNumber || ''}</span>
                        </div>

                        <p style={{marginTop: '2em'}} >Payment</p>
                        <CardElement />
                        {/* <button className='button'
                        <div>
                            <
                        </div> */}
                        <div className='columns'>
                            <div className='column'>

                            </div>
                        </div>
                        {/* </div>
                        < div className='column is-half'>
                            <p>Hi {user.displayName}</p>
                        </div > */}
                    </div >
                }
            </React.Fragment>
        )
    }

}

export default injectStripe(Account);