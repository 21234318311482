import React from 'react';
import './App.scss';
import firebase, { db } from "./firebase";
import * as firebaseui from 'firebaseui';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(firebase.auth());

class Login extends React.Component {
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)

    // Hold a reference to the anonymous current user.
    var anonymousUser = firebase.auth().currentUser;
    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
      ],
      autoUpgradeAnonymousUsers: true,
      // signInFlow: 'popup',
      signInSuccessUrl: '/',
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          console.log(authResult)
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true;
        },
        uiShown: function () {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none';
        },
        signInFailure: function (error) {
          anonymousUser = firebase.auth().currentUser;
          if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
            return Promise.resolve();
          }
          // The credential the user tried to sign in with.
          var cred = error.credential;
          // Save anonymous user data first.
          return db.collection('users').doc(firebase.auth().currentUser.uid).get()
            .then(async function (doc) {
              const user = await firebase.auth().signInWithCredential(cred);
              console.log(user.user.uid)
              if (doc.data()) await db.collection('users').doc(user.user.uid).set(doc.data(), { merge: true });
              // Delete anonymnous user.
              console.log(user.user.uid)

              if (anonymousUser) {
                await db.collection('users').doc(anonymousUser.uid).delete();
                await anonymousUser.delete();
              }
              console.log(user.user.uid)

              // FirebaseUI will reset and the UI cleared when this promise
              // resolves.
              // signInSuccessWithAuthResult will not run. Successful sign-in
              // logic has to be run explicitly.
              // console.log(this.props)
              // this.props.history.goBack();
              // window.location.assign('<url-to-redirect-to-on-success>');
              window.location.assign('/account');
            });
        }
      },
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      tosUrl: '<your-tos-url>',
      // Privacy policy url/callback.
      privacyPolicyUrl: function () {
        window.location.assign('<your-privacy-policy-url>');
      }
    });
  }

  render() {

    return (
      <React.Fragment >
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
      </React.Fragment>
    )

  }

}

export default withRouter(Login)


