import React from 'react';
import './App.scss';
import Nav from './Nav';
import ProductCard from './ProductCard';
import { Route, withRouter } from 'react-router-dom';
import firebase, { db } from './firebase';
import Progress from './Progress';
import queryString from 'query-string';


 class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            loading: true
        }
        db.collection('products').get().then(products => this.setState({ loading: false, products: [...products.docs] }))
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const manufacturer = parsed.manufacturer ? parsed.manufacturer : '';
        const category = parsed.category ? parsed.category : '';
        const flavor = parsed.filter ? parsed.filter : '';
        
        const setProducts = products =>this.setState({ loading: false, products: [...products.docs] });
        if (manufacturer) db.collection('products').where('manufacturer', '==', manufacturer).get().then(setProducts)
        if (category) db.collection('products').where(category, '==', true).get().then(setProducts)
        if (flavor) db.collection('products').where('flavor', '==', flavor).get().then(setProducts)
        

    }

    render() {
        console.log(this.state)
        return (

            <React.Fragment>
                {/* <img className='image is-1024x1024' src='/imgs/sale.jpg' /> */}
                <div className='content products columns is-1 is-mobile is-multiline has-text-centered'

                >

                    <Route exact path='/' render={() =>
                        <div className='column is-full'><p className='has-text-dark-grey is-size-5'>Your vaping needs delivered right to your door</p></div>} />
                    {
                        this.state.loading && <Progress />
                    }
                    {this.state.products.map((product, idx) => {
                        product = product.data();
                        console.log(product)
                        return (
                            <div className='column is-half is-pulled-right' ><ProductCard product_info={product} style={(idx % 2 === 0) ? 'left' : 'right'} /></div>
                        )
                    })}
                </div>
            </React.Fragment>
        )

    }

}


export default withRouter(Products);