import React from 'react';
import storage from './storage';
import { type } from 'os';
import { db } from './firebase';
import user  from './user';


let _items = [];
const _callbacks = [];

user.get_cart().then(cart => fill(cart || []))

const onChange = (cb) => _callbacks.push(cb);
const _updateObservers = () => _callbacks.forEach(cb => cb(_items))

const fill = (items) => {
    _items = items;
    storage.set('cart', items)
        .then(_updateObservers())
        .catch(err => console.error(err))
}

const getSubtotal = (display = false) => {
    const subtotal = _items.reduce((total, item) => total + parseInt(item.price), 0);
    if (!display) return subtotal;
    return formatPrice(subtotal);
}

const getTaxes = (display = false) => {
    const subtotal = getSubtotal();
    if (!display) return Math.round(subtotal * .0975);
    return formatPrice(Math.round(subtotal * .0975));
}

const getDeliveryFee = (display = false) => {
    if (!display) return 875;
    return formatPrice(875);
}

const getTotal = (display = false) => {
    const total = getSubtotal() + getTaxes() + getDeliveryFee();
    if (!display) return total;
    return formatPrice(total);
}

const set = (item, quantity) => {
    const items = _items.filter(_item => _item.name !== item.name);
    while (quantity--) items.push(item)
    fill(items);
}

const remove = (item, quantity = 1) => {
    let items = [..._items]
    while (quantity--) {
        const idx = items.findIndex((_item => _item.name === item.name));
        if (!idx) console.error(`Failed to find ${item} in cart`);
        items = items.slice(0, idx).concat(items.slice(idx + 1));
    }
    fill(items);
}

const add = (item, quantity = 1) => {
    const items = [..._items];
    while (quantity--) items.push(item);
    fill(items)
}

const getItems = () => [..._items]
const clear = () => { fill([]) }
const formatPrice = (price) => price.toString().slice(0, -2) + '.' + price.toString().slice(-2);

export default {
    getItems: getItems,
    fill: fill,
    add: add,
    remove: remove,
    clear: clear,
    onChange: onChange,
    getSubtotal: getSubtotal,
    getTaxes: getTaxes,
    getDeliveryFee: getDeliveryFee,
    getTotal: getTotal,
    formatPrice: formatPrice
}


