import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Home from './Home';
import Product from './Product';
import Popular from './Popular';
import Login from './Login';
import './firebase';



export default () => (
  <Router>
    <Switch>
      {/* <Route path="/auth" component={Login} /> */}
      {/* <Route exact path="/popular" component={Popular} /> */}
      <Route  path="/" component={Home} />
      {/* <Route path="/popular" component={Popular} /> */}
    </Switch>
  </Router>
);
