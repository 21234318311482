import React from 'react';
import storage from './storage';
import { withRouter } from 'react-router-dom';
import places from 'places.js';
import {db} from './firebase'
import user from './user'

class AddressSearch extends React.Component {

    constructor() {
        super();
        this.state = { user: null, address: '' }
        // storage.get('user')
        //     .then(async user => {
        //         if (user) {
        //             this.setState({ user: user });
        //             const data = (await db.collection('users').doc(user.uid).get()).data();
        //             console.log(data)
        //             this.setState({ address: data.address })
        //         } else this.setState({ address: (await storage.get('address'))})
        //     }).catch(err => console.error(err));
        this.updateAddress = this.updateAddress.bind(this);
    }

    componentDidMount() {
        const placesAutocomplete = places({
            appId: 'pl91KZTIU2LQ',
            apiKey: '2a2c7db927ea1669bcf4f14547f8cfb4',
            container: document.querySelector('#address-input')
        }).configure({
            countries: ['us'],
            type: 'address'
        })
        placesAutocomplete.on('error', e => console.error(e))
        placesAutocomplete.on('change', async e => {
            const suggestion = e.suggestion;
            const address = {
                address_line: suggestion.name, 
                city: suggestion.city, 
                state: suggestion.administrative, 
                postcode: suggestion.postcode || '', 
                country: suggestion.countryCode
            };
            this.setState({address: address});
            // await storage.set('address', address)
            // if (user.exists()) await user.set_doc_data({address: address})
            await user.set_address(address);
            this.props.history.goBack();
        })
    }

    updateAddress = async (address) => {
        this.setState({ address: address })
    }

    render() {
        return (
            <div id='address-search' style={{padding: '0 1em', margin: '10vh 0'}}>
                <p className='has-text-left is-size-3 is-family-primary'>Enter your address</p>
                <input id='address-input'  type='search' defaultValue={this.state.address} onChange={e => this.updateAddress(e.target.value)} />
            </div>
            // <div className="modal" ref={this.modal_ref}>
            //     <div className="modal-background" style={{ backgroundColor: 'rgba(255, 255, 255, 0.86)' }}/>
            //     <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
            //         <div className="column"></div>
            //     </div>
            //     <button className="modal-close is-large" aria-label="close"></button>
            // </div>

        )
    }
}

export default withRouter(AddressSearch);