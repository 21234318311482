import React from 'react';
import './App.scss';
import Nav from './Nav';
import ProductCard from './ProductCard';
import { Route, withRouter } from 'react-router-dom';
import firebase, {db} from './firebase';
import Progress from './Progress';
// const products = [
//     { name: 'America Patriots Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'America-Patriots-Salt.png' },
//     { name: 'Lava Flow Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Lava-Flow-Salt.png' },
//     { name: 'Brain Freeze Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Brain-Freeze-Salt.png' },
//     { name: 'Maui Sun Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Maui-Sun-Salt.png' },
//     { name: 'Polar Breeze Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Polar-Breeze-Salt.png' },
//     { name: 'Very Cool Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Very-Cool-Salt.png' },
//     { name: 'Hawaiian POG Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Hawaiian-POG-Salt.png' },
//     { name: 'Amazing Mango Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Amazing-Mango-Salt.png' },
//     { name: 'Green Blast Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Green-Blast-Salt.png' },
//     { name: 'Really Berry Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Really-Berry-Salt.png' },
//     { name: 'Arctic Air Salt', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999', photo: 'Arctic-Air-Salt.png' },
// ];
export default class Popular extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false,
            products: [],
            loading: true
        }
        db.collection('products').get().then(products => this.setState({loading: false, products: [...products.docs]}))
    }

    // <section>
    //     <Nav />
    /* <div className='products is-hidden-touch columns is-mobile is-multiline has-text-centered'
        style={{ width: '100%', marginLeft: 0, marginTop: '10vh', zIndex: 20, alignItems: 'flex-end' }}
    >
        {products.map(product => (
            <div className='column is-one-third'><Product product_info={product} /></div>
        ))}
    </div>

</section> */

    render() {
        return (

            <React.Fragment>
                        {/* <img className='image is-1024x1024' src='/imgs/sale.jpg' /> */}
                <div className='content products columns is-1 is-mobile is-multiline has-text-centered'
                    
                >
                    
                        <Route exact path='/' render={() => 
                        <div className='column is-full'><p className='has-text-dark-grey is-size-5'>Your vaping needs delivered right to your door</p></div>} />
                    {
                        this.state.loading && <Progress/>
                    }
                    {this.state.products.map((product, idx) => {
                        product = product.data();
                        return (
                            <div className='column is-half is-pulled-right' key={idx}><ProductCard key={idx} product_info={product} style={(idx % 2 === 0) ? 'left' : 'right'} /></div>
                        )
                    })}
                </div>
            </React.Fragment>
        )

    }

}


