import React from 'react';
import storage from './storage';
import cart from './cart';
import { db } from './firebase';
import { CardElement, injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import { withRouter } from 'react-router-dom';
import { ConfigBase } from 'aws-sdk/lib/config';
class CartView extends React.Component {
    constructor() {
        super();
        this.state = { items: cart.getItems(), address: null }
        cart.onChange(items => { this.setState({ items: items }) })
        storage.get('user')
            .then(async user => {
                console.log(user)
                // if (!user)  console.log(this.props); return this.props.history.push('/auth')
                const user_data = (await db.collection('users').doc(user.uid).get()).data();
                if (user_data) this.setState({ address: user_data.address })
            })
        }

        

    onComponentDidMount() {
        cart.onChange(items => { this.setState({ items: items }) });

        const paymentRequest = this.props.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Demo total',
                amount: 1000,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        console.log(paymentRequest)
        var prButton = this.props.stripe.elements().create('paymentRequestButton', {
            paymentRequest: paymentRequest,
        });
        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(function (result) {
            console.log(result)
            if (result) {
                prButton.mount('#payment-request-button');
            } else {
                console.log(result)
                document.getElementById('payment-request-button').style.display = 'none';
            }
        });

        paymentRequest.on('token', ev => console.log(ev))
    
    }

    render() {

        const { address } = this.state;
        const items = this.state.items.reduce((items, item) => ({ ...items, [item.name]: { quantity: (items[item.name] ? items[item.name]['quantity'] : 0) + 1, price: item.price, item: item } }), {})
        return (
            <section className='content'>
                <div className="columns is-vcentered is-mobile">
                    {address &&
                        <React.Fragment>
                            <div className="column">
                                <img className="image 400x400"
                                    src={'https://maps.googleapis.com/maps/api/staticmap?center=' + encodeURI(`${address.address_line} ${address.city} ${address.state}`) + '&zoom=17&size=400x400&key=AIzaSyAe2buyb6d4OWp0itplh2VOjt-kR3ARUfs'} />
                            </div>
                            <div id="checkout-address" className="column has-text-left">
                                <p>{address.address_line}</p>
                                <p>{address.city}, {address.state}</p>
                                <textarea className="textarea is-primary" placeholder="Delivery instructions" defaultValue={address.delivery_instructions || ''}></textarea>
                            </div>
                        </React.Fragment>
                    }

                </div>
                {Object.entries(items).map(([item_name, item]) => (
                    <div key={item_name} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <span>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center', }}>
                                <a className="button is-outlined" style={{ borderRadius: '20px' }}
                                    onClick={() => cart.remove(item.item)}>
                                    <p className=''>-</p></a>
                                <p className='' style={{ margin: '0 1rem 0 1rem' }}>{item.quantity}</p>
                                <a className="button is-outlined " style={{ borderRadius: '20px', userSelect: 'none' }}
                                    onClick={() => cart.add(item.item)}>
                                    <p className=''>+</p></a>
                            </div>

                        </span>
                        <span>{item_name}</span>
                        <span>{cart.formatPrice(item.price * item.quantity)}</span>
                    </div>))}
                {/* <div >
                    <input className='input' type='text' placeholder='Coupon Code' />
                </div> */}
                <div style={{ marginTop: '1em' }}>
                    <span>Subtotal</span>
                    <span className='is-pulled-right'>{cart.getSubtotal(true)}</span>
                </div>
                <div >
                    <span>Taxes</span>
                    <span className='is-pulled-right'>{cart.getTaxes(true)}</span>
                </div>
                <div >
                    <span>Delivery Fee</span>
                    <span className='is-pulled-right'>{cart.getDeliveryFee(true)}</span>
                </div>
                <div >
                    <span>Total</span>
                    <span className='is-pulled-right'>{cart.getTotal(true)}</span>
                </div>
                <div style={{ marginTop: '1em' }}>
                    <div id="payment-request-button"></div>
                    {/* <CardElement /> */}
                </div>
            </section>
        )
    }
}

export default withRouter(injectStripe(CartView));
