import React from 'react';
import './App.scss';
// import lava_flow from 'lava-flow';
import classNames from 'classnames';
import cart from './cart';
import {withRouter} from 'react-router-dom';


class ProductCard extends React.Component {
    constructor() {
        super();
        this.state = {
            is_clicked: false
        }
        this.modal_ref = React.createRef();
    }

    render() {
        let { product_info, style } = this.props;
        if (!product_info) product_info = { name: 'Lave Flow', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999' }
        return (
            < div
                className={classNames('product', 'is-family-primary', 'is-size-3', 'has-text-dark-grey', 'columns', { 'is-pulled-right': style === 'left' })}
                style={{ backgroundColor: '#ffffffa3' }}
                onClick={() => {
                    this.props.history.push(`/product/${encodeURI(product_info.name)}/?fromPath=${this.props.location.pathname}${this.props.location.search}`)
                    this.modal_ref.current.classList.toggle('is-active')
                }}>

                <div className="modal" ref={this.modal_ref}>
                    <div className="modal-background"
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.86)' }}
                    ></div>
                    <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
                        <div className="column">
                            <p className='is-size-2 has-text-primary'>{product_info.name}</p>
                            <p className='is-size-4 has-text-grey'> {product_info.brand}</p>
                        </div>
                        <div className="column">
                            {/* <img className="image is-768x729" src={`imgs/${product_info.images[0]}`} style={{ mixBlendMode: 'multiply' }} /> */}
                            <img className="image is-768x729" src={`/imgs/${product_info.images[0]}`} />
                        </div>
                        <div className="column">
                            <div className='columns is-vcentered'>
                                <div className="column">
                                    <p className='is-size-5 has-text-black-ter'>{product_info.description}</p>
                                </div>
                                <div className="column">
                                    <p className='is-size-5 has-text-dark-grey price-text'>${product_info.price.slice(0, -2)}.{product_info.price.slice(-2)}</p>
                                    <button className="button" onClick={() => cart.add(product_info)}>Add to Cart</button>
                                </div>
                            </div>


                        </div>
                    </div>
                    <button className="modal-close is-large" aria-label="close"></button>
                </div>

                <div className="product-column is-hidden-desktop has-text-left is-centered">
                    <img className="product-list-image image is-768x729" src={`/imgs/${product_info.images[0]}`} style={{ cursor: 'pointer' }} />
                    {/* <p className='is-size-4 has-text-primary text' style={{ whiteSpace: 'nowrap' }}>{product_info.name}</p> */}
                    <p className='is-size-4 has-text-primary text' >{product_info.name}</p>
                    <p className='is-size-5 has-text-grey'> {product_info.brand}</p>
                    <p className='is-size-5 has-text-dark-grey price-text'>${product_info.price.slice(0, -2)}.{product_info.price.slice(-2)}</p>
                </div>
                <div className={
                    classNames('product-column', 'is-hidden-touch', 'has-text-center', 'is-narrow', { 'is-pulled-right': style === 'left' }, { 'is-pulled-left': style === 'right' })}>
                    <img className="product-list-image image is-768x729" src={`/imgs/${product_info.images[0]}`} style={{ cursor: 'pointer' }} />
                    <p className='is-size-2 has-text-primary' style={{ cursor: 'pointer' }}>{product_info.name}</p>
                    <p className='is-size-4 has-text-grey'> {product_info.brand}</p>
                    <p className='is-size-5 has-text-dark-grey price-text'>${product_info.price.slice(0, -2)}.{product_info.price.slice(-2)}</p>
                </div>


            </div >
        )
    }

}

export default withRouter(ProductCard);