import React from 'react';
import './App.scss';
import ReactMapGL, { Popup, GeolocateControl } from 'react-map-gl';
import Nav from './Nav';
import Popular from './Popular';
import Brands from './Brands';
import Product from './Product';
import Products from './Products';
import Login from './Login';
import Checkout from './Checkout';
import storage from './storage';
import cart from './cart';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Route, Switch } from 'react-router-dom';
import Account from './Account';
import AddressSearch from './AddressSearch';
import firebase from './firebase';
// import { Interactions } from 'aws-amplify';
// import { ChatBot, AmplifyTheme } from 'aws-amplify-react';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_visible: false,
            viewport: {
                width: '100%',
                height: '130vh',
                latitude: 26.025899,
                longitude: -80.162383,
                zoom: 12
            }
        }
        // const params = new URLSearchParams(props.location.search);
        // console.log(params)
    }

    getPosition() {
        navigator.geolocation.getCurrentPosition(
            position => {
                this.state.position = position
                this.state.viewport = {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                }
                console.log(position)
                this.getPosition();
            },
            error => {
                console.log(error)
            },
            {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 10000,
            });
        navigator.geolocation.watchPosition(
            position => {
                this.state.position = position
                this.state.viewport = {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                }
                console.log(position)
                this.getPosition();
            },
            error => {
                console.log(error)
            },
            {
                enableHighAccuracy: true,
                maximumAge: 30000,
                timeout: 10000,
            });
    }

    async componentDidMount() {
        await storage.set('hi', 'there');
    }
    render() {
        return (
            <React.Fragment>
                <Nav />
                {/* <ChatBot
                    title="My Bot"
                    // theme={myTheme}
                    botName="BookTrip"
                    welcomeMessage="Welcome, how can I help you today?"
                    onComplete={(err, confirmation) => console.log(err, confirmation)}
                    clearOnComplete={true}
                    conversationModeOn={false}
                /> */}
                <Route exact path="/popular" render={() => <Popular />} />
                <Route path="/products" render={() => <Products />} />
                <Route exact path="/address-search" render={() => <AddressSearch />} />
                <Route exact path="/account" render={() =>
                    <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
                        <Elements>
                            <Account />
                        </Elements>
                    </StripeProvider>
                } />
                <Route exact path="/checkout" render={() =>
                    <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
                        <Elements>
                            <Checkout />
                        </Elements>
                    </StripeProvider>
                } />
                <Route exact path="/product/:product_name" render={() => <Product />} />
                <Route exact path="/brands" render={() => <Brands />} />
                <Route path='/auth' render={() => <Login />} />
                <Route exact path="/" render={() => <Popular />} />
                <Route exact path="/map" render={() => (
                    <ReactMapGL
                        mapboxApiAccessToken={'pk.eyJ1IjoianVpY2Vkb29yIiwiYSI6ImNqdnE2NmI0MjJoMnYzenFyMmxyYTNwNHAifQ.neveSr-LuxbXLk7t3oG5zA'}
                        {...this.state.viewport}
                        onViewportChange={(viewport) => this.setState({ viewport })}
                        style={{ position: 'fixed', top: 0 }}
                    >
                    </ReactMapGL>
                )} />
                {/*<GeolocateControl
                        style={{ position: 'absolute', top: '10vh', right: 0, margin: '1em', zIndex: 10 }}
                        onViewportChange={viewport => this.setState({ viewport })}
                        positionOptions={{ enableHighAccuracy: true }}
                        trackUserLocation={true}
                        showUserLocation={true}
                    /> */}
                {/* <Popup latitude={37.78} longitude={-122.41} closeButton={true} closeOnClick={false} anchor="top">
                        <div>You are here</div>
                    </Popup> */}
            </React.Fragment>

        )
    }



}


