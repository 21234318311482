import localforage from 'localforage'
import { async } from 'q';

localforage.config({
    driver      : localforage.WEBSQL, // Force WebSQL; same as using setDriver()
    name        : 'jdweb',
    version     : 0.1,
    size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName   : 'jdwebkvpairs', // Should be alphanumeric, with underscores.
});

const storage = {
    get: async (key) => await localforage.getItem(key),
    set: async (key, value) => await localforage.setItem(key, value),
    clear: async () => await localforage.clear()
}


export default storage;