import React from 'react';
import './App.scss';
// import lava_flow from 'lava-flow';
import classNames from 'classnames';
import cart from './cart';
import { withRouter } from 'react-router-dom';
import { db } from './firebase';
import queryString from 'query-string';

class Product extends React.Component {
    constructor() {
        super();
        this.state = { product: null, loading: true, quantity: 1, fromPath: '' }
        console.log(this.props)
    }

    async componentDidUpdate() {
        console.log(this.props)

    }
    async componentDidMount() {
        console.log(this.props)
        let product = await db.collection('products').where('name', '==', decodeURI(this.props.match.params.product_name)).get();
        if (!product) console.error('Failed to get product')
        console.log(product)
        product = product.docs[0].data();
        this.setState({ product: product, loading: false });

        const parsed = queryString.parse(this.props.location.search);
        if (parsed.fromPath) this.setState({fromPath: parsed.fromPath})


    }
    render() {
        console.log(this.state)
        let { product, loading } = this.state;
        // console.log(style)
        // if (!product) product = { name: 'Lave Flow', brand: 'Naked 100 EJuice', description: 'the dopest dope you ever doped', price: '1999' }
        return (
            <section>
                {loading &&
                    <progress className="progress is-small is-primary" max="100">1%</progress>
                }
                {product &&
                    <React.Fragment>
                        <div className='content'>

                            < div
                                className={classNames('product', 'is-family-primary',  'has-text-dark-grey')}
                                style={{ backgroundColor: '#ffffffa3' }}>
                                <div className="is-hidden-desktop has-text-left">
                                    <img className="product-list-image image is-768x729" src={`/imgs/${product.images[0]}`} style={{ cursor: 'pointer' }} />
                                    <p className='is-size-4 has-text-primary text' style={{ whiteSpace: 'nowrap', marginBottom: '.1em' }}>{product.name}</p>
                                    <p className='is-size-6 has-text-grey' style={{ marginBottom: '.1em' }}> {product.manufacturer}</p>
                                    <p className='is-size-5 has-text-dark-grey price-text'>${product.price.slice(0, -2)}.{product.price.slice(-2)}</p>
                                </div>
                                <div className={
                                    classNames('is-hidden-touch', 'has-text-center', 'is-narrow')}>
                                    <img className="product-list-image image is-768x729" src={`imgs/${product.images[0]}`} style={{ cursor: 'pointer' }} />
                                    <p className='is-size-2 has-text-primary' styl e={{ cursor: 'pointer' }}>{product.name}</p>
                                    <p className='is-size-4 has-text-grey'> {product.brand}</p>
                                    <p className='is-size-5 has-text-dark-grey price-text'>${product.price.slice(0, -2)}.{product.price.slice(-2)}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'baseline', justifyContent: 'center', marginBottom: '1em' }}>
                                    <a className="button is-outlined is-size-4" style={{ borderRadius: '20px' }} onClick={() => { if (this.state.quantity > 1) this.setState({ quantity: this.state.quantity - 1 }) }}>
                                        <p className='is-size-3'>-</p></a>
                                    <p className='is-size-4' style={{ margin: '0 1rem 0 1rem' }}>{this.state.quantity}</p>
                                    <a className="button is-outlined is-size-4" style={{ borderRadius: '20px', userSelect: 'none' }} onClick={() => this.setState({ quantity: this.state.quantity + 1 })}>
                                        <p className='is-size-3'>+</p></a>
                                </div>
                                <p>{product.description}</p>
                            </div >
                        </div >
                            <div id="navbar-cart-add" className="navbar-brand has-text-center is-family-primary"
                                onClick={() => {
                                    cart.add(product, this.state.quantity)
                                    if (this.state.fromPath) this.props.history.push(this.state.fromPath)
                                    else this.props.history.push('/')
                                }}
                            >
                                <p className="navbar-item">Add to Cart</p>
                            </div>
                    </React.Fragment>
                }
            </section>
        )
    }

}

export default withRouter(Product);