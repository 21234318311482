import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import storage from './storage';
import cart from './cart';

firebase.initializeApp({
  apiKey: "AIzaSyByT18jQSn0rcWWJ0jkR-8DqmKwgWIlP20",
  authDomain: "jewstore.firebaseapp.com",
  databaseURL: "https://jewstore.firebaseio.com",
  projectId: "jewstore",
  storageBucket: "jewstore.appspot.com",
  messagingSenderId: "323160317137",
  appId: "1:323160317137:web:bf83e9909f2ecda4"
});
export const db = firebase.firestore();

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    await storage.set('user', user)
    console.log(user)
    console.log(firebase.auth().currentUser)
    // db.collection('users').doc(user.uid).set({...user}, {merge: true})
  }
  else await storage.set('user', null)
});
// firebase.auth().signInAnonymously().then(
  // )
cart.onChange(items => {
  if (firebase.auth().currentUser) db.collection('users').doc(firebase.auth().currentUser.uid).set({cart: items}, {merge: true})
})

export default firebase;